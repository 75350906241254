<template>
  <b-card>
    <b-row>
      <b-col md="6" xl="4">
        <b-form-group label="Product" label-for="product">
          <b-form-select
            id="product"
            v-model="form.product_id"
            :options="product"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <h4 class="my-2">Detail Peserta Polis</h4>
    <b-row>
      <b-col md="6" xl="4">
        <b-form-group label="KTP" label-for="id-card">
          <b-form-input
            id="id-card"
            max="16"
            type="number"
            v-model="form.nik"
            :change="updateValue"
          />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="Nama" label-for="name">
          <b-form-input id="name" v-model="form.name" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6" xl="4">
        <b-form-group label="Tempat Lahir" label-for="born-place">
          <b-form-input id="born-place" v-model="form.birth_place" />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="Tanggal Lahir" label-for="birthdate">
          <flat-pickr
            v-model="form.birthdate"
            class="form-control"
            :config="datepicker"
          />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="Jenis Kelamin" label-for="gender">
          <b-form-select id="gender" v-model="form.gender" :options="gender" />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="No Telp" label-for="phone">
          <b-form-input
            id="phone"
            maxlength="13"
            type="number"
            v-model="form.phone"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="Email" label-for="email">
          <b-form-input id="email" v-model="form.email" />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="Pekerjaan" label-for="job">
          <b-form-select id="job" v-model="form.job" :options="job" />
        </b-form-group>
      </b-col>
    </b-row>
    <h4 class="my-2">Alamat Lengkap</h4>
    <b-row>
      <b-col xl="12">
        <b-form-group label="Alamat" label-for="address">
          <b-form-textarea id="address" v-model="form.address" />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="Desa / Kelurahan" label-for="village">
          <b-form-input id="village" v-model="form.village" />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="Kecamatan" label-for="district">
          <b-form-input id="email" v-model="form.district" />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="Kota / Kabupaten" label-for="city">
          <b-form-input id="city" v-model="form.city" />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="Provinsi" label-for="province">
          <b-form-input id="province" v-model="form.province" />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="Kode Pos" label-for="postal-code">
          <b-form-input
            id="postal-code"
            v-model="form.postal_code"
            type="number"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <h4 class="my-2">Dokumen Pendukung</h4>
    <b-row>
      <b-col md="6" xl="4">
        <b-form-group label="Nama Bank" label-for="bank-name">
          <b-form-input id="bank-name" v-model="form.bank_name" />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="No Rekening" label-for="bank-account-no">
          <b-form-input
            id="bank-account-no"
            v-model="form.account_number"
            type="number"
          />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group
          label="Nama Pemilik Rekening"
          label-for="bank-account-name"
        >
          <b-form-input id="bank-account-name" v-model="form.account_name" />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="KTP / Akta Lahir / KK" label-for="identity">
          <b-form-input id="identity" v-model="form.account_name" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-button block class="mt-2" variant="success">Kirim</b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BButton,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BButton,
    flatPickr,
  },
  data() {
    return {
      datepicker: {
        dateFormat: "d-m-Y",
      },
      product: [],
      job: [],
      gender: [],
      form: {
        nik: "",
        name: null,
        birth_place: null,
        birthdate: null,
        gender: null,
        phone: null,
        email: null,
        address: null,
        village: null,
        district: null,
        city: null,
        province: null,
        postal_code: null,
        product_id: 1,
        job_id: null,
        bank_name: null,
        account_name: null,
        account_number: null,
        image_identity: null,
      },
    };
  },
  created() {
    this.$http
      .get("/master/product")
      .then((response) => {
        let $data = response.data;

        for (let i = 0; i < $data.length; i++) {
          this.product.push({
            value: $data[i].id,
            text: $data[i].name,
          });
        }

        console.log(this.product);
      })
      .catch((error) => {
        console.log(error);
      });

    this.$http
      .get("/master/job")
      .then((response) => {
        let $data = response.data;

        for (let i = 0; i < $data.length; i++) {
          this.job.push({
            value: $data[i].id,
            text: $data[i].name,
          });
        }

        console.log(this.job);
      })
      .catch((error) => {
        console.log(error);
      });

    this.$http
      .get("/master/gender")
      .then((response) => {
        let $data = response.data;

        for (let i = 0; i < $data.length; i++) {
          this.gender.push({
            value: $data[i].id,
            text: $data[i].name,
          });
        }

        console.log(this.gender);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    updateValue(e) {
      console.log(this.form.nik);
    },
    onSend() {
      this.$http
        .post("/policy", this.form)
        .then((res) => {
          this.$bvToast.toast("Berhasil membuat polis baru", {
            title: "Berhasil",
            variant: "success",
            solid: true,
            autoHideDelay: 1000,
          });
          this.$router.push("/policies");
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast(err.response.data.message, {
            title: "Error",
            variant: "danger",
            solid: true,
            autoHideDelay: 1000,
          });
        });
    },
    makeToast(variant = null) {
      this.$bvToast.toast("Toast body content", {
        title: `Variant ${variant || "default"}`,
        variant,
        solid: true,
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
